import * as React from "react";
import "./style.scss";
import TrackVisibility from "react-on-screen";
import { isNullOrWhiteSpace } from "../../common/helper/helper";

export interface IAnimatedLaptopProps {
  monitorImageUrl?: string
  height?: number
}

export const AnimatedLaptop = (props: IAnimatedLaptopProps) => {
  const [isOpen, SetOpen] = React.useState<boolean>(true);
  const style: React.CSSProperties = {
    height: props.height && props.height > 0 ? `${props.height}px` : "410px"
  };
  return (
    <TrackVisibility>
      {({ isVisible }) => (
        <>
          <div className="container">
            <div className={`laptop ${isOpen && isVisible ? "" : "laptop--closed"}`} style={style}>
              <div className="laptop-block" onClick={() => SetOpen(true)}></div>
              <div className="laptop-top" onClick={() => SetOpen(false)}>
                <div></div>
                <div>
                  <img src={isNullOrWhiteSpace(props.monitorImageUrl) ? "https://media.giphy.com/media/SVgCHJ2n35EA6XFPpO/giphy.gif" : props.monitorImageUrl} />
                </div>
              </div>
              <div className="laptop-bottom">
                <div>
                  <div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div></div>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </>
      )}
    </TrackVisibility>
  )
}
